import CommonLanding from "../components/common/CommonLanding";
import HowItWorks from "../components/pages/HowItWorks";

const HowItWorksPage = () => {
	return (
		<>
			<CommonLanding title='Gasolina App' />
			<HowItWorks />
		</>
	);
};

export default HowItWorksPage;
