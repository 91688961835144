import {
	CiBadgeDollar,
	CiCircleChevDown,
	CiFaceSmile,
	CiHashtag,
	CiPickerEmpty,
} from "react-icons/ci";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { SvgLeft, SvgRight } from "../layout/Svgs";
import { NavLink } from "react-router-dom";

const HowItWorks = () => {
	const howItWorks = [
		{
			title: "Sign Up",
			description:
				"Create a free account in minutes to start exploring available listings.",
			icon: <CiPickerEmpty />,
		},
		{
			title: "Set MPIN",
			description:
				"Secure account by setting a MPIN for quick and safe access to your Gasolina app.",
			icon: <CiHashtag />,
		},
		{
			title: "Complete Profile",
			description:
				"Fill in your details to unlock all the features and benefits of the Gasolina app",
			icon: <CiFaceSmile />,
		},
		{
			title: "Start Paying",
			description:
				"Finalize the rental process easily with the help of our expert team.",
			icon: <CiBadgeDollar />,
		},
	];

	return (
		<section className='how-it-works pos-rel' id='how-it-works'>
			<SvgRight className='svg-top' />
			<div className='container'>
				<div className='row'>
					<div className='top mar-0-a t-a-c'>
						<h2 className='color-primary f-w-800'>How It Works</h2>
						<br />
						<hr className='sep mar-0-a' />
						<br />
						<p>
							Sign up for free, browse through verified apartment
							listings, and schedule viewings with ease. Once you
							find your ideal home, finalize the rental process
							seamlessly with our expert support.
						</p>
					</div>
					<div className='all mar-0-a'>
						{howItWorks.map((r, i) => (
							<div className='one' key={i}>
								<div className='b-r-100 mar-0-a icon flex center-center'>
									{r.icon}
								</div>
								<div className='t t-a-c'>
									<h6 className='color-primary'>{r.title}</h6>
									<hr className='sep mar-0-a' />
									<p className=''>{r.description}</p>
								</div>
							</div>
						))}
					</div>
					<div className='btn t-a-c'>
						<NavLink to='/download'>
							<ButtonTextIcon
								title='Download App'
								icon={<CiCircleChevDown />}
							/>
						</NavLink>
					</div>
				</div>
			</div>
			<SvgLeft className='svg-bottom' />
		</section>
	);
};

export default HowItWorks;
