import { useState } from "react";

const Single = ({ data, num }) => {
	const [show, setShow] = useState(false);

	return (
		<div className='one'>
			<div
				className={`question transition ${show ? "question-open" : ""}`}
				onClick={() => setShow(!show)}>
				<p
					className={`f-w-600 _t ${show ? "_t-open" : ""}`}
					dangerouslySetInnerHTML={{
						__html: `<span>${num}.</span> ${data.title}`,
					}}></p>
				<svg
					className={`_icon ${show ? "_icon-open" : ""}`}
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='15'
					viewBox='0 0 24 15'>
					<path
						d='M0,7.33,2.829,4.5,12,13.839,21.171,4.5,24,7.33,12,19.5Z'
						transform='translate(0 -4.5)'
					/>
				</svg>
			</div>
			{show && (
				<div className='answer'>
					<p
						dangerouslySetInnerHTML={{
							__html: data.description,
						}}></p>
				</div>
			)}
		</div>
	);
};

const FAQs = () => {
	const questions = [
		{
			title: "How do I create an account?",
			description:
				'You can create an account by downloading the Gasolina app from your app store, clicking on the "Sign Up" button, and following the prompts to enter your personal information and set your MPIN.',
		},
		{
			title: "Can I track my fuel expenses?",
			description:
				"Yes! The Gasolina app provides real-time transaction tracking, allowing you to view your fueling history and monitor your expenses conveniently within the app.",
		},
		{
			title: "Is android and ios both available?",
			description:
				"Yes, the Gasolina app is available for both Android and iOS devices. You can download it from the Google Play Store for Android or the Apple App Store for iOS, ensuring that users on both platforms can enjoy the convenience of mobile wallet payments at gas stations across South Africa.",
		},
		{
			title: "Is my payment information secure with Gasolina?",
			description:
				"Yes, your payment information is secure. The Gasolina app uses advanced encryption and secure payment gateways to protect your personal and financial data, ensuring a safe transaction experience every time you fuel up.",
		},
		{
			title: "How do I top up my wallet?",
			description:
				'Navigate to the "Wallet" section or "Top Up" option. Enter the amount you wish to add to your wallet. Confirm the transaction, and your wallet balance will be updated instantly.',
		},
		{
			title: "Is there a Scan & Pay feature? How do I use it?",
			description:
				"Yes, the Gasolina app includes a convenient scan and pay feature that allows you to pay for fuel by simply scanning a QR code at the gas station. When you arrive at the station, open the app, select the scan option, and point your camera at the displayed QR code. This enables a quick and contactless transaction, making your fueling experience even more efficient.",
		},
	];

	return (
		<section id='home-page'>
			<section className='faqs' id='faqs'>
				<div className='container'>
					<div className='top mar-0-a t-a-c'>
						<h2 className='color-primary f-w-800'>
							Frequently Asked Questions
						</h2>
						<br />
						<hr className='sep mar-0-a' />
						<br />
						<p>
							Have questions? Find quick answers to common
							inquiries about payment process to app details,
							we’ve got you covered!
						</p>
					</div>
					<div className='questions mar-0-a'>
						{questions.map((q, i) => (
							<Single data={q} key={i} num={i + 1} />
						))}
					</div>
				</div>
			</section>
		</section>
	);
};

export default FAQs;
