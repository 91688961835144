import CommonLanding from "../components/common/CommonLanding";
import FAQs from "../components/pages/FAQs";

const FAQsPage = () => {
	return (
		<>
			<CommonLanding title='FAQs' />
			<FAQs />
		</>
	);
};

export default FAQsPage;
