import HeroSection from "../components/pages/HeroSection";
import Introduction from "../components/pages/Introduction";
import Features from "../components/pages/Features";
import HowItWorks from "../components/pages/HowItWorks";
import FAQs from "../components/pages/FAQs";
import Download from "../components/pages/Download";
import ContactSection from "../components/pages/ContactSection";

const Home = () => {
	return (
		<>
			<HeroSection />
			<Introduction />
			<Features />
			<HowItWorks />
			<FAQs />
			<Download />
			{/* <ContactSection /> */}
		</>
	);
};

export default Home;
