import { SvgLeft, SvgRight } from "../layout/Svgs";

const CommonLanding = ({ title }) => {
	return (
		<section id='common-page'>
			<section className='landing pos-rel of-hid'>
				<SvgRight className='top' />
				<div className='container'>
					<div className='txt t-a-c'>
						<h1 className='color-primary l-h-1 f-w-800'>{title}</h1>
					</div>
				</div>
				<SvgLeft className='bottom' />
			</section>
		</section>
	);
};

export default CommonLanding;
