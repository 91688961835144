import { NavLink } from "react-router-dom";
import { VERSION } from "../../utilities/constants";
import MainLogo from "../common/MainLogo";

const Footer = () => {
	return (
		<footer>
			<div className='container'>
				<div className='flex center-center'>
					<NavLink to='/'>
						<MainLogo className='logo' />
					</NavLink>
				</div>
				<ul className='links'>
					<li className='l-s-n'>
						<NavLink to='/privacy-policy'>Privacy Policy</NavLink>
					</li>
					<li className='l-s-n'>
						<NavLink to='/contact'>Contact Us</NavLink>
					</li>
					<li className='l-s-n'>
						<NavLink to='/terms'>Terms and Conditions</NavLink>
					</li>
				</ul>
				<p
					className='t-a-c small color-white'
					style={{ margin: "10px 0" }}>
					Copyright &copy;2024
					<br />
					<NavLink to='/'>Gasolina</NavLink> - All Rights Reserved.
				</p>
				<p
					className='t-a-c small color-white'
					style={{
						opacity: 0.4,
					}}>
					Version v{VERSION}
				</p>
			</div>
		</footer>
	);
};

export default Footer;
