import CommonLanding from "../components/common/CommonLanding";
import Introduction from "../components/pages/Introduction";

const IntroPage = () => {
	return (
		<>
			<CommonLanding title='Gasolina App' />
			<Introduction />
		</>
	);
};

export default IntroPage;
