import { CiCircleChevDown, CiCircleQuestion } from "react-icons/ci";
import ButtonLightTextIcon from "../ui/ButtonLightTextIcon";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import Illustration from "../../images/home/landing.svg";
import { SvgLeft, SvgRight } from "../layout/Svgs";
import { NavLink } from "react-router-dom";
import { TAGLINE } from "../../utilities/constants";

const HeroSection = () => {
	return (
		<section className='hero' id='home'>
			<div className='col flex center-center'>
				<div className='col-1-1'>
					<div className='container'>
						<div className='row flex bottom-right'>
							<div className='col-1-2 left'>
								<div className='txt'>
									<h1 className='f-w-800 color-primary title l-h-1-2'>
										Gasolina
									</h1>
									<h4 className='f-w-400 color-light l-h-1'>
										{TAGLINE}
									</h4>
									<br />
									<p
										className='desc color-light'
										style={{ marginBottom: "10px" }}>
										Gasolina provides a seamless solution
										for wallet payments at gas stations
										across South Africa, making fueling
										faster, safer, and more convenient.
									</p>
									<p className='desc color-light'>
										With Gasolina, customers can pay
										directly from their mobile wallets,
										eliminating the need for cash or cards
										and ensuring a smooth, contactless
										transaction.
									</p>
									<div className='btns'>
										<NavLink to='/download'>
											<ButtonTextIcon
												title='Download App'
												icon={<CiCircleChevDown />}
											/>
										</NavLink>
										<NavLink to='/how-it-works'>
											<ButtonLightTextIcon
												title='How it Works'
												icon={<CiCircleQuestion />}
											/>
										</NavLink>
									</div>
								</div>
							</div>
							<div className='col-1-2 right'>
								<img
									className='col-1-1 height-auto display-block'
									src={Illustration}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SvgLeft className='bottom' />
			<SvgRight className='top' />
		</section>
	);
};

export default HeroSection;
