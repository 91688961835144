import { APP_STORE_URL, PLAY_STORE_URL } from "../../utilities/constants";
import Image from "../../images/home/app-dashboard.png";
import PlayStore from "../../images/home/play-store.png";
import AppStore from "../../images/home/app-store.png";

const Download = () => {
	return (
		<section className='introduction' id='download'>
			<div className='container'>
				<div className='row flex'>
					<div className='left col-2-5 col'>
						<img
							className='col-1-1 height-auto display-block'
							src={Image}
						/>
					</div>
					<div className='right col-3-5 col flex center-center'>
						<div className='txt'>
							<h1 className='f-w-800 color-primary title l-h-1-2'>
								Download the Apps
							</h1>
							<h4 className='f-w-400 color-light l-h-1'>
								Available at the App Store & Play Store
							</h4>
							<br />
							<p className='color-light'>
								Experience the convenience of fueling with
								Gasolina by downloading our app today! Available
								on both Android and iOS, the Gasolina app allows
								you to make secure mobile wallet payments, track
								your expenses, and earn rewards — all from the
								palm of your hand.
							</p>
							<br />
							<p className='color-light'>
								Visit the Google Play Store or Apple App Store,
								search for Gasolina, and start enjoying a
								smarter way to pay for fuel!
							</p>
							<div className='btns'>
								<a
									target='_blank'
									rel='noreferrer'
									href={PLAY_STORE_URL}>
									<img src={PlayStore} />
								</a>
								<a
									target='_blank'
									rel='noreferrer'
									href={APP_STORE_URL}>
									<img src={AppStore} />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Download;
