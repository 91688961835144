// ====== APPLICATION CONSTANTS ====== //
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const MODE = process.env.REACT_APP_MODE;
export const EMAIL_ADDRESS = "info@gasolinasa.com";
export const PHONE_NO = "+27 987 654 3210";
export const ADDRESS =
	"34 The Avenue Road, Gardens, Johannesburg, Gauteng, 2192";

// ====== Constants ====== //

export const TAGLINE = "Fuel made easy.";
export const PLAY_STORE_URL = "https://play.google.com/";
export const APP_STORE_URL = "https://www.apple.com/app-store/";
