import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MainLogo from "../common/MainLogo";

const Header = () => {
	const { pathname } = useLocation();
	const [menu, setMenu] = useState(false);
	useEffect(() => {
		const onScroll = () => {
			const header = document.getElementById("header");
			if (window.scrollY > 0) {
				header.classList.add("fixed");
			} else {
				header.classList.remove("fixed");
			}
		};
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	});

	const MENU_LINKS = [
		{
			title: "Home",
			url: "/",
		},
		{
			title: "Introduction",
			url: "/app",
		},
		{
			title: "Features",
			url: "/features",
		},
		{
			title: "How it Works",
			url: "/how-it-works",
		},
		{
			title: "FAQs",
			url: "/faqs",
		},
		{
			title: "Download",
			url: "/download",
		},
	];

	useEffect(() => {
		routeChangeStart();
		window.scrollTo(0, 0);
	}, [pathname]);

	const openMenu = () => {
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "hidden";
		document.getElementsByTagName("nav")[0].classList.add("open");
	};

	const closeMenu = () => {
		document.getElementsByTagName("nav")[0].classList.remove("open");
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "auto";
	};

	useEffect(() => {
		menu ? openMenu() : closeMenu();
	}, [menu]);

	const routeChangeStart = () => {
		if (menu) {
			setMenu(false);
			closeMenu();
		}
	};

	return (
		<header id='header' className='transition'>
			<div className='container'>
				<div className='flex center-left row'>
					<div
						title='Menu'
						className={`menu-btn ${menu ? "menu-btn-open" : ""}`}
						onClick={() => setMenu(!menu)}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className='logo'>
						<NavLink to='/'>
							<MainLogo className='transition' />
						</NavLink>
					</div>
					<nav>
						<ul>
							{MENU_LINKS.map((m, i) => (
								<li key={i}>
									<NavLink to={m.url}>{m.title}</NavLink>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
