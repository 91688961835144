import { CiLocationOn, CiMail, CiPhone } from "react-icons/ci";
import { ADDRESS, EMAIL_ADDRESS, PHONE_NO } from "../../utilities/constants";
import Image from "../../images/home/contact-bg.jpg";

const ContactSection = () => {
	return (
		<section id='home-page'>
			<section className='contact' id='contact'>
				<div className='row flex'>
					<div
						className='col-1-2 left cover-img'
						style={{
							backgroundImage: `url(${Image})`,
						}}>
						<img src={Image} />
					</div>
					<div className='col-1-2 right'>
						<div className='txt'>
							<h2 className='color-primary f-w-800'>
								We're available
							</h2>
							<br />
							<hr className='sep' />
							<br />
							<p>
								We're here to make it easy for you to reach out
								with any questions, feedback, or support you
								might need about Gasolina App.
							</p>
							<br />
							<p>
								Whether you're a customer needing assistance, a
								business interested in partnering with us, or
								just curious about how we can improve your
								fueling experience, we’d love to hear from you.
							</p>
							<br />
							<div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiMail
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-primary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Email
									</p>
									<p>
										<a href={`mailto:${EMAIL_ADDRESS}`}>
											{EMAIL_ADDRESS}
										</a>
									</p>
								</div>
							</div>
							<br />
							{/* <div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiPhone
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-primary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Phone
									</p>
									<p>
										<a href={`tel:${PHONE_NO}`}>
											{PHONE_NO}
										</a>
									</p>
								</div>
							</div>
							<br /> */}
							<div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiLocationOn
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-primary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Address
									</p>
									<p>{ADDRESS}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default ContactSection;
