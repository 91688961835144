const CommonContent = ({ children }) => {
	return (
		<section id='common-page'>
			<section className='content-area'>
				<div className='container'>{children}</div>
			</section>
		</section>
	);
};

export default CommonContent;
