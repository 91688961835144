import CommonLanding from "../components/common/CommonLanding";
import Features from "../components/pages/Features";

const FeaturesPage = () => {
	return (
		<>
			<CommonLanding title='Gasolina Features' />
			<Features />
		</>
	);
};

export default FeaturesPage;
