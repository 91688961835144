import CommonLanding from "../components/common/CommonLanding";
import ContactSection from "../components/pages/ContactSection";

const ContactPage = () => {
	return (
		<>
			<CommonLanding title='Contact Us!' />
			<ContactSection />
		</>
	);
};

export default ContactPage;
