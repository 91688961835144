const SvgRight = ({ ...props }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='324.5'
			height='324.5'
			viewBox='0 0 324.5 324.5'
			{...props}>
			<g transform='translate(324.5)'>
				<path
					d='M0,324.5c-40-8.2-79.9-16.4-122.1-29.8-42.1-13.4-86.5-32-107.4-65.2-20.8-33.3-18.2-81.3-30.1-122S-298,33.4-324.5,0H0Z'
					fill='#E8EEF6'
				/>
				<path
					d='M0,259.6c-32-6.6-63.9-13.1-97.7-23.8-33.7-10.7-69.2-25.6-85.9-52.2-16.7-26.7-14.5-65-24.1-97.6-9.5-32.5-30.7-59.3-51.9-86H0Z'
					fill='#B8CDE5'
				/>
				<path
					d='M0,194.7c-24-4.9-48-9.8-73.2-17.9-25.3-8-52-19.2-64.5-39.1-12.5-20-10.9-48.8-18.1-73.2-7.1-24.4-23-44.4-38.9-64.5H0Z'
					fill='#88AED3'
				/>
				<path
					d='M0,129.8c-16-3.3-32-6.6-48.8-11.9-16.9-5.4-34.6-12.8-43-26.1-8.3-13.3-7.3-32.5-12-48.8-4.8-16.3-15.4-29.6-26-43H0Z'
					fill='#508FC2'
				/>
				<path
					d='M0,64.9a216.47,216.47,0,0,1-24.4-6c-8.4-2.6-17.3-6.4-21.5-13-4.2-6.7-3.6-16.3-6-24.4S-59.6,6.7-64.9,0H0Z'
					fill='#2980B9'
				/>
			</g>
		</svg>
	);
};

const SvgLeft = ({ ...props }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='324.5'
			height='324.5'
			viewBox='0 0 324.5 324.5'
			{...props}>
			<g transform='translate(0 324.5)'>
				<path
					d='M0-324.5c34.3,26.3,68.5,52.7,106.8,66.7C145-243.7,187.3-241.9,222-222c34.8,19.8,62,57.8,77.8,97.8S320.1-42.1,324.5,0H0Z'
					fill='#E8EEF6'
				/>
				<path
					d='M0-259.6c27.4,21.1,54.8,42.1,85.4,53.4,30.6,11.2,64.4,12.7,92.2,28.6s49.6,46.2,62.2,78.3C252.5-67.3,256-33.7,259.6,0H0Z'
					fill='#B8CDE5'
				/>
				<path
					d='M0-194.7c20.6,15.8,41.1,31.6,64.1,40,22.9,8.5,48.3,9.6,69.1,21.5,20.9,11.9,37.2,34.7,46.7,58.7S192-25.2,194.7,0H0Z'
					fill='#88AED3'
				/>
				<path
					d='M0-129.8c13.7,10.5,27.4,21.1,42.7,26.7s32.2,6.3,46.1,14.3c13.9,7.9,24.8,23.1,31.1,39.1S128-16.8,129.8,0H0Z'
					fill='#508FC2'
				/>
				<path
					d='M0-64.9c6.9,5.3,13.7,10.5,21.4,13.3,7.6,2.9,16.1,3.2,23,7.2,7,4,12.4,11.6,15.6,19.6,3.1,8,4,16.4,4.9,24.8H0Z'
					fill='#2980B9'
				/>
			</g>
		</svg>
	);
};

export { SvgRight, SvgLeft };
