import "./css/globals.scss";
import "./css/base.scss";
import "./css/pages.scss";
import "./css/responsive.scss";
import { Route, Routes } from "react-router-dom";
import WithLayout from "./hoc/withLayout";
import Home from "./pages/Home";
import IntroPage from "./pages/IntroPage";
import FeaturesPage from "./pages/FeaturesPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import FAQsPage from "./pages/FAQsPage";
import DownloadPage from "./pages/DownloadPage";
import ContactPage from "./pages/ContactPage";
//  ================================================ //
import NotFound from "./pages/NotFound";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const App = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<WithLayout>
						<Home />
					</WithLayout>
				}
			/>
			<Route
				path='/app'
				element={
					<WithLayout>
						<IntroPage />
					</WithLayout>
				}
			/>
			<Route
				path='/features'
				element={
					<WithLayout>
						<FeaturesPage />
					</WithLayout>
				}
			/>
			<Route
				path='/how-it-works'
				element={
					<WithLayout>
						<HowItWorksPage />
					</WithLayout>
				}
			/>
			<Route
				path='/faqs'
				element={
					<WithLayout>
						<FAQsPage />
					</WithLayout>
				}
			/>
			<Route
				path='/download'
				element={
					<WithLayout>
						<DownloadPage />
					</WithLayout>
				}
			/>
			<Route
				path='/contact'
				element={
					<WithLayout>
						<ContactPage />
					</WithLayout>
				}
			/>
			<Route
				path='/privacy-policy'
				element={
					<WithLayout>
						<PrivacyPolicyPage />
					</WithLayout>
				}
			/>
			<Route
				path='/terms'
				element={
					<WithLayout>
						<TermsPage />
					</WithLayout>
				}
			/>
			<Route
				path='*'
				element={
					<WithLayout>
						<NotFound />
					</WithLayout>
				}
			/>
		</Routes>
	);
};

export default App;
