import {
	CiBadgeDollar,
	CiCircleChevDown,
	CiClock2,
	CiLocationArrow1,
	CiLock,
	CiWallet,
} from "react-icons/ci";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { NavLink } from "react-router-dom";

const Features = () => {
	const data = [
		{
			title: "Mobile Wallet Payments",
			description:
				"Pay for fuel directly from your mobile wallet, providing a fast, secure, and contactless payment experience.",
			icon: <CiWallet />,
		},
		{
			title: "Real-Time Transaction Tracking",
			description:
				"Keep track of your fuel expenses with instant notifications and detailed transaction history.",
			icon: <CiClock2 />,
		},
		{
			title: "Rewards Program",
			description:
				"Earn points on every purchase and redeem rewards, making each refuel more rewarding.",
			icon: <CiBadgeDollar />,
		},
		{
			title: "Station Locator",
			description:
				"Easily find nearby gas stations that accept Gasolina wallet payments with the built-in locator feature.",
			icon: <CiLocationArrow1 />,
		},
		{
			title: "Secure Payment",
			description:
				"Enjoy encrypted, secure transactions, ensuring your payment details are protected at all times.",
			icon: <CiLock />,
		},
	];

	return (
		<section id='home-page'>
			<section className='features' id='features'>
				<div className='container'>
					<div className='row flex'>
						<div className='col-1-3 left'>
							<h2 className='color-primary f-w-800'>
								App Features
							</h2>
							<br />
							<hr className='sep' />
							<br />
							<p>
								Gasolina App Features provide a convenient and
								secure way to pay for fuel at gas stations
								across South Africa. The app allows users to
								make instant payments using their mobile
								wallets, offering a contactless and cashless
								experience.
							</p>
							<NavLink to='/download'>
								<ButtonTextIcon
									title='Download App'
									icon={<CiCircleChevDown />}
								/>
							</NavLink>
						</div>
						<div className='col-2-3 right'>
							<div className='all'>
								{data.map((r, i) => (
									<div className='one' key={i}>
										<div className='icon'>{r.icon}</div>
										<div className='t'>
											<h6 className='color-primary'>
												{r.title}
											</h6>
											<p className='l-h-1-2'>
												{r.description}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default Features;
