import { NavLink } from "react-router-dom";
import CommonContent from "../components/common/CommonContent";
import CommonLanding from "../components/common/CommonLanding";
import { EMAIL_ADDRESS } from "../utilities/constants";

const TermsPage = () => {
	return (
		<>
			<CommonLanding title='Terms & Conditions of Use' />
			<CommonContent>
				<div
					className='mar-0-a'
					style={{
						maxWidth: "1120px",
					}}>
					<p>
						The following Terms and Conditions of Use govern the
						Gasolina Application (“the Gasolina App”).
					</p>
					<br />
					<p>
						Kindly review the Terms and Conditions of Use together
						with the Gasolina Privacy Notice prior to use of the
						Gasolina App. By accessing this Gasolina App or any
						functionality thereof on any electronic device,
						including your computer, mobile phone, tablet or any
						other device (“Device”), you hereby confirm that you
						have read the Terms and Conditions of Use when
						registering on the Gasolina App and accept such Terms
						and Conditions of Use.
					</p>
					<br />
					<p>
						Effective Date: <strong>01/10/2024</strong>
					</p>
					<br />
					<h4>1. DEFINITIONS</h4>
					<p>
						The definitions recorded below have the following
						meanings:
					</p>
					<p>
						“App User” means the Customer registered and using the
						Gasolina App.
					</p>
					<p>
						“Contact Centre” means the Gasolina Customer Service
						Centre with contact details, e-mail at {EMAIL_ADDRESS}
					</p>
					<p>
						“Gasolina/we/us/our” means Seritoglo (Pty) Ltd
						(Registration Number: 2021/322075/07).
					</p>
					<p>
						“Gasolina Site Locator” means the site locator software
						developed by Gasolina and housed in the Gasolina App
						which allows the App User to locate all Gasolina
						affiliated sites in its network and products supplied
						and services rendered at such retail site.
					</p>
					<p>
						“User Content” means the text and documents the App User
						uploads and/or transmits when using the Gasolina App.
					</p>
					<p>
						“You/Your” means the Gasolina customer registered on the
						Gasolina App as an App User.
					</p>
					<br />
					<h4>2. INTRODUCTION</h4>
					<ul>
						<li>
							<p>
								2.1 You are required to read and understand the
								Gasolina App Terms and Conditions of Use prior
								to registration.
							</p>
						</li>
						<li>
							<p>
								2.2 Questions related to the Term and Conditions
								may be addressed to the Gasolina Customer
								Service Centre team via e-mail at
								{EMAIL_ADDRESS}.
							</p>
						</li>
						<li>
							<p>
								2.3 Registration and use of the Gasolina App is
								considered acceptance of the{" "}
								<NavLink to='/terms'>
									<strong>
										<span className='t-d-ul'>
											Terms and Conditions
										</span>
									</strong>
								</NavLink>{" "}
								of Use set out herein.
							</p>
						</li>
						<li>
							<p>2.4 An App User must be 18 years or older.</p>
						</li>
						<li>
							<p>
								2.5 By using the Gasolina App, you are given
								access to the Gasolina application, services,
								interfaces, payment platforms, software and
								other functions as stipulated in the Gasolina
								App.
							</p>
						</li>
					</ul>
					<br />
					<h4>3. OWNER AND OPERATOR INFORMATION</h4>
					<p>
						3.1 The Gasolina App is owned and operated by Seritoglo
						(Pty) Ltd, a private limited company registered in
						accordance with company laws of South Africa,
						registration number 2021/322075/07, with principal place
						of business at, 34 The Avenue Road, Gardens,
						Johannesburg, Gauteng, 2192.
					</p>
					<br />
					<h4>4. REGISTRATION AND MOBILE SERVICES</h4>
					<p>
						4.1 The Gasolina App is free to download for your
						personal, non-commercial use, and is available on
						various mobile operating systems, including Android and
						iOS. Gasolina grants you a limited non-exclusive licence
						to use the Gasolina App in accordance with these Terms
						and Conditions of Use.
					</p>
					<p>
						4.2 To access certain features of the Gasolina App you
						will need internet access, which costs are solely borne
						by the App User.
					</p>
					<p>
						4.3 The terms of your agreement with your mobile network
						provider will continue to apply when using the Gasolina
						App and You accept all charges related to such use.
					</p>
					<p>
						4.4 By using the Gasolina App you agree that Gasolina
						may communicate with you electronically via your Device
						and the Gasolina App.
					</p>
					<p>
						4.5 You are required to have a compatible Device to make
						use of the Gasolina App. The Gasolina App contains
						features that may only be available on certain mobile
						Devices.
					</p>
					<p>
						4.6 When creating your user account for the Gasolina App
						you will be prompted to follow the registration process.
					</p>
					<p>
						4.7 During the registration process the App User will be
						prompted to register by e-mail and select a secure
						password, and once registered, the User may either log
						on to the App by using his secure e-mail and password,
					</p>
					<p>4.8 Gasolina App Push Notifications</p>
					<p>
						The Gasolina App includes push notifications or other
						mobile communication capability. You hereby approve our
						delivery of electronic communications directly to your
						Device. These notifications may include [eg. information
						regarding your account balances or recent transactions.]
						The notifications may be delivered to your Device even
						when the App is running in the background. You have the
						ability, and it is your responsibility, to control the
						notifications you do, or do not, receive through your
						Device. It is your responsibility to keep your account
						information secure when using this feature. It is Your
						duty to familiarise yourself with the End User License
						of your Device and Your use of the Push Application
						Software related to such Device, particularly the
						security of Your information as exchanged. The User
						indemnifies Gasolina, its affiliates, officers,
						directors, employees, agents, licensors, contractors and
						suppliers against any unauthorised access or use of
						their account due to negligence by the User in
						safeguarding his credentials.
					</p>
					<p>4.9 Cookies</p>
					<p>
						By using the Gasolina App, you acknowledge and consent
						to the use of cookies. These cookies may collect and
						store information about your usage patterns,
						preferences, and Device, which may impact your browsing
						experience and allow us to improve our services. You
						have the option to manage or disable cookies through
						your browser settings.
					</p>
					<br />
					<h4>5.DATA PROTECTION</h4>
					<p>
						5.1 Your personal details and other information relevant
						to you and provided by you to Gasolina or which Gasolina
						may gain access though the Gasolina App will only be
						used in accordance with Gasolina’s Privacy Notice. We
						request that you read Gasolina’s Privacy Notice before
						continuing with Gasolina’s App. You acknowledge and
						agree that by downloading the Gasolina App and creating
						/ registering a profile, you are consenting to
						Gasolina’s use of your personal information in
						accordance with Gasolina’s Privacy Notice, see{" "}
						<NavLink to='/privacy-policy'>
							<strong>
								<span className='t-d-ul'>
									https://gasolinasa.com/privacy-policy
								</span>
							</strong>
						</NavLink>
						, which is read as if incorporated in these Terms and
						Conditions of Use.
					</p>
					<p>
						5.2 Whilst your privacy as well as the safeguarding and
						appropriate handling of your personal information are of
						paramount importance to Gasolina, please note that it is
						your responsibility to safeguard your email address,
						mobile phone number, passwords, One Time Pins (“OTPs”),
						Mobile Pins (“MPIN”), app user details or any other
						information forming part of Gasolina’s security
						procedures and ensure that it is kept confidential and
						not divulged to anybody else.
					</p>
					<p>
						5.3 In the event of someone else using your mobile phone
						number, username or password to make changes to your
						Gasolina App, you will be held responsible for the
						changes and the outcome thereof and Gasolina carries no
						responsibility in any manner whatsoever, of loss
						resulting from any unauthorised use of your details,
						with or without your knowledge.
					</p>
					<p>
						5.4 You may from time to time be required to verify
						changes to your account through an OTP.
					</p>
					<br />
					<h4>6. FAIR AND ACCEPTABLE USE POLICY</h4>
					<p>
						6.1 The App User may use any part of the App and print
						copies of such information for the App User’s personal
						and non-commercial use.
					</p>
					<p>
						6.2 The App User may not store, alter, copy, print,
						distribute or broadcast or download any material from
						the Gasolina App for commercial use.
					</p>
					<p>
						6.3 The App User may not use the Gasolina App in any
						unlawful manner and/or for any unlawful purpose and/or
						in any manner in conflict with these Terms and
						Conditions of Use.
					</p>
					<p>
						6.4 The App User may not act fraudulently or maliciously
						when using the Gasolina App and cause any harm to the
						Gasolina App or Gasolina’s security technology e.g.,
						hacking, viruses, malware or damage to its software or
						operating system.
					</p>
					<p>
						6.5 The App User may not use the Gasolina App in any way
						that compromises Gasolina App’s security, data or
						interferes with the use of the Gasolina App by other App
						Users.
					</p>
					<p>
						6.6 If you change or update your Device, it remains your
						responsibility to immediately update your account
						information to ensure Gasolina does not continue
						communicating with a party then in possession of your
						number or Device.
					</p>
					<br />
					<h4>7. COMMUNICATION</h4>
					<p>
						7.1 As part of signing up for the Gasolina App, Gasolina
						will directly send You information regarding the
						Gasolina App and related features, related product and
						marketing information and promotional offers.
					</p>
					<p>7.2 Direct marketing</p>
					<p>
						By agreeing to these Terms and Conditions of Use, you
						are agreeing to receive direct marketing communications
						on the Gasolina App relating to:
					</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;7.2.1 Gasolina’s products and
						services – we value you as a Gasolina customer, so as we
						create new products and services, promotions, and
						offers, some in conjunction with our third-party
						partners, we may send you communications about these
						items. We might also send you market research / customer
						surveys or other interesting news we have to share.
					</p>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;7.2.2 Other communications</p>
					<p>
						&nbsp;&nbsp;&nbsp;&nbsp;Gasolina may send you certain
						administrative and servicing communications while you
						are using the Gasolina App.
					</p>
					<p>
						7.3 Managing direct marketing communications
						preferences.
					</p>
					<p>
						You can opt out of and manage the categories and
						channels of direct marketing communications that you
						wish to receive from Gasolina at any time by updating
						your preferences on the Gasolina App. You can also
						opt-out of receiving certain marketing communications
						from us by using the opt-out links provided in the
						marketing communications.
					</p>
					<br />
					<h4>8. INDEMNIFICATION</h4>
					<p>
						You agree to indemnify, defend and hold harmless
						Gasolina, its affiliates, officers, directors,
						employees, agents, licensors, contractors and suppliers
						from and against all claims, losses, liabilities,
						expenses, damages and costs, of whatsoever nature,
						including without limitation, legal costs arising from
						or relating in any way to your User Content, your use of
						Content, your use of the Gasolina App or any violations
						of these Terms and Conditions, any law or rights to any
						third party.
					</p>
					<br />
					<h4>9. EXCLUSION OF LIABILITY</h4>
					<p>
						9.1 While Gasolina takes all reasonable measures to
						ensure that the information it provides is correct at
						time of the inclusion in the Gasolina App, Gasolina
						makes no representations, undertaking or warranties,
						express or implied, as to the integrity, functionality
						or accuracy of the information provided.
					</p>
					<p>
						9.2 Save where liability cannot be excluded in law,
						Gasolina will not be liable to the App User, whether in
						contract, delict (including negligence), breach of
						statutory duty or otherwise, whether direct or indirect
						or consequential in nature, arising out of or in
						connection with the use of the Gasolina App or reliance
						on any information contained in the Gasolina App.
					</p>
					<br />
					<h4>10. INTELLECTUAL PROPERTY RIGHTS</h4>
					<p>
						10.1 All intellectual property contained in or on the
						Gasolina App (except for “User Content”) is owned by
						Gasolina or its licensors. All content in the Gasolina
						App (except for User Content) including but not limited
						to, text, software, scripts, code, designs, graphics,
						photos, sounds music, videos, interactive features and
						all other content (“Content”) is collective work under
						applicable copyright laws and is the proprietary
						property of Gasolina. Gasolina reserves all its rights
						in respect of the Intellectual Property Rights contained
						in the Gasolina App and in respect of the Content.
					</p>
					<p>
						10.2 The Gasolina App contains trademarks including but
						not limited to the mark “Gasolina”. All trademarks
						included on this Gasolina App are owned by Gasolina or
						its licensors. Gasolina reserves all of its rights in
						respect of the trademarks included on the Gasolina App.
					</p>
					<p>
						10.3 Nothing in these Terms and Conditions of Use shall
						be interpreted as granting to the App User any license
						of Intellectual Property Rights owned by Gasolina or its
						licensors.
					</p>
					<br />
					<h4>11. VARIATION</h4>
					<p>
						11.1 Gasolina may, in its sole discretion, amend the
						format or content of the Gasolina App at any time, with
						or without notice to the App User.
					</p>
					<p>
						11.2 Gasolina may also suspend operation of the Gasolina
						App, or features or functionalities, for support or
						maintenance work, including updating content, with or
						without notice to the App User.
					</p>
					<p>
						11.3 Updates to the Gasolina App may be issued through
						the Appstore, Google Play or Huawei App Gallery and may
						require downloading of the latest version of the
						Gasolina App which may include acceptance of new Terms
						and Conditions of Use.
					</p>
					<p>
						11.4 Gasolina may amend the Terms and Conditions of Use
						of the Gasolina App at any time and without notice,
						which changes shall be effective on the date of posting
						such amendments. In the event of such amendments, the
						App User will be notified of these changes and referred
						to the website for viewing these Terms and Conditions.
					</p>
					<p>
						11.5 Should the App User not agree with the amendments
						as effected; the App User must immediately delete
						his/her Gasolina App account. Your continued use of the
						Gasolina App will be considered an acceptance of the
						amended Terms and Conditions of Use.
					</p>
					<br />
					<h4>12. TERMINATION</h4>
					<p>
						12.1 Gasolina reserves the right in its sole discretion
						to terminate the App User’s account and/or access to the
						Gasolina App or any features or functionalities thereof,
						delete your profile and/or any User Content and/or
						restrict any or part of the App User’s use of the
						Gasolina App for any reason it may deem reasonable.
						After such termination or restriction, the App User may
						not create a new account to circumvent termination,
						deletion or restriction.
					</p>
					<br />
					<h4>13. GENERAL</h4>
					<p>
						13.1 Legal Notices - All legal notices are to be
						delivered by hand at Gasolina’s registered address as
						set out in par 3 being Gasolina’s chosen domicilium for
						service of all legal process.
					</p>
					<p>
						13.2 Disclaimer – The use of the Gasolina App is
						entirely at the App User’s own risk and the App User
						assumes all risk or loss resulting from use of the
						Gasolina App, disclaiming any and all liability for any
						damage, loss of whatever nature out of or in connection
						with the App Users access to or use of the Gasolina App.
						The App User further remains responsible for the
						accuracy of the information the App User enters or
						submits to the Gasolina App.
					</p>
					<p>
						13.3 Jurisdiction – These Terms and Conditions are
						governed by the laws of the Republic of South Africa.
					</p>
					<p>
						13.4 Transfer, Cession or Assignment – Gasolina may
						transfer, cede or assign its rights and obligations in
						terms of these Terms and Conditions of Use to any
						Affiliate of Gasolina. “Affiliate” means with respect to
						a Party, any corporate entity. With legal personality
						that controls, is controlled by, or is under common
						control with such Party. An entity shall be regarded as
						being in control of another entity if it owns, directly
						or indirectly, or is entitled to exercise, directly or
						indirectly, the votes attaching to at least 50 % (fifty
						per cent) of the equity share capital of the other
						entity, or if it possesses, directly or indirectly, the
						power to determine the composition of the majority of
						the board of directors of the other entity.
					</p>
					<p>
						13.5 Entire Agreement - The Terms and Conditions
						constitute the entire agreement between the App User and
						Gasolina in relation to the Gasolina App. Gasolina will
						not be bound by any undertakings, representations,
						warranties, promises, terms or conditions or the like
						not recorded herein.
					</p>
				</div>
			</CommonContent>
		</>
	);
};

export default TermsPage;
