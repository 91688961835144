import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

const WithLayout = ({ children }) => {
	return (
		<main>
			<Header />
			{children}
			<Footer />
		</main>
	);
};

export default WithLayout;
