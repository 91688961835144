import CommonLanding from "../components/common/CommonLanding";
import Download from "../components/pages/Download";

const DownloadPage = () => {
	return (
		<>
			<CommonLanding title='Download App!' />
			<Download />
		</>
	);
};

export default DownloadPage;
