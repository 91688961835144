import { CiCircleChevDown, CiCircleQuestion } from "react-icons/ci";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import ButtonLightTextIcon from "../ui/ButtonLightTextIcon";
import AppImage from "../../images/home/app-login-screen.png";
import { NavLink } from "react-router-dom";
import { TAGLINE } from "../../utilities/constants";

const Introduction = () => {
	return (
		<section className='introduction' id='introduction'>
			<div className='container'>
				<div className='row flex'>
					<div className='left col-2-5 col'>
						<img
							className='col-1-1 height-auto display-block'
							src={AppImage}
						/>
					</div>
					<div className='right col-3-5 col flex center-center'>
						<div className='txt'>
							<h1 className='f-w-800 color-primary title l-h-1-2'>
								Introducing Gasolina...
							</h1>
							<h4 className='f-w-400 color-light l-h-1'>
								{TAGLINE}
							</h4>
							<br />
							<p className='color-light'>
								Welcome to Gasolina, the smarter way to pay at
								gas stations across South Africa. We’ve
								revolutionized the fueling experience by
								introducing seamless mobile wallet payments,
								making it faster, safer, and more convenient to
								pay for fuel.
							</p>
							<br />
							<p className='color-light'>
								Whether you're in a hurry or just want a
								hassle-free transaction, Gasolina allows you to
								skip the lines, avoid handling cash or cards,
								and enjoy a contactless payment process right
								from your phone. Our goal is to bring modern
								convenience to everyday fueling, ensuring that
								you can pay with ease and confidence wherever
								you go.
							</p>
							<br />
							<p className='color-light'>
								With robust technology and a focus on customer
								satisfaction, Gasolina is dedicated to enhancing
								the way South Africans refuel. We're committed
								to providing a fast, reliable service that
								empowers users to take control of their payments
								and enjoy a frictionless experience at every
								stop.
							</p>
							<div className='btns'>
								<NavLink to='/download'>
									<ButtonTextIcon
										title='Download App'
										icon={<CiCircleChevDown />}
									/>
								</NavLink>
								<NavLink to='/how-it-works'>
									<ButtonLightTextIcon
										title='How it Works'
										icon={<CiCircleQuestion />}
									/>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Introduction;
