import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import { NavLink } from "react-router-dom";

const NotFound = () => {
	return (
		<section id='not-found' className='cover-img'>
			<div
				style={{
					height: "860px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
				}}>
				<div>
					<h4 className='color-primary'>Error 404.</h4>
					<h1 className='color-primary'>Page not found</h1>
					<br />
					<p>
						The page you are looking for has vanished or does not
						exist.
					</p>
					<p>Return home instead.</p>
					<br />
					<br />
					<NavLink to='/'>
						<ButtonTextIcon
							title='Return Home'
							icon={<CiLogin />}
						/>
					</NavLink>
				</div>
			</div>
		</section>
	);
};

export default NotFound;
