import { NavLink } from "react-router-dom";
import CommonContent from "../components/common/CommonContent";
import CommonLanding from "../components/common/CommonLanding";
import { ADDRESS, EMAIL_ADDRESS } from "../utilities/constants";

const PrivacyPolicyPage = () => {
	return (
		<>
			<CommonLanding title='Privacy Notice – Gasolina Application' />
			<CommonContent>
				<div
					className='mar-0-a'
					style={{
						maxWidth: "1120px",
					}}>
					<p>
						The Gasolina App (“Gasolina App”) is Gasolina’s
						multi-channel app that allows Gasolina customers to
						facilitate payment for transactions at fuel stations. We
						take your privacy seriously and would like to make you
						aware of how we use your personal information entrusted
						to us when you download, create and register a profile
						on the Gasolina App.
					</p>
					<br />
					<p>
						This notice will provide you with details of processing
						activities you can expect from us, how we will protect
						your personal information and your rights under
						applicable data protection laws such as the Protection
						of Personal Information Act (‘POPIA’). Please note, it
						is not intended that the Gasolina App be utilised by
						individuals below the age of eighteen (18) years of age
						(“children” or “child”), and we do not knowingly collect
						personal information relating to children. Where we
						discover that children have created a profile on the
						Gasolina App, we will immediately deactivate that
						child’s account and delete their personal information.
						If you are not 18 years of age or older, please do not
						provide us with your personal information for purposes
						of the Gasolina App.
					</p>
					<br />
					<p>
						Personal information is any information that can be used
						to determine who you are as an individual (including
						juristic persons in South Africa), for example your
						name, your e-mail address, IP address and identity
						number.
					</p>
					<br />
					<h4>
						Who is responsible for processing personal information
						and who is the Data Privacy Officer?
					</h4>
					<p>
						Seritoglo Proprietary Limited (also referred to as
						“Gasolina, we, us, our”), is the responsible party
						relating to your personal information, meaning that we
						decide how we hold, use and secure your personal
						information.
					</p>
					<br />
					<p>
						<strong>Address:</strong> {ADDRESS}
					</p>
					<p>
						For any enquiries, you can reach the Data Privacy
						Officer at{" "}
						<a className='t-d-ul' href={`mailto:${EMAIL_ADDRESS}`}>
							<strong>{EMAIL_ADDRESS}</strong>
						</a>
						.
					</p>
					<br />
					<h4>
						Which categories of personal information do we process?
					</h4>
					<p>
						Kindly note, whilst some of the personal information you
						provide may be voluntary (which we will clearly
						indicate), other types of personal information will be
						required in order for you to effectively use the
						Gasolina App and in order for us to make the full
						functionality of the App available to you in accordance
						with this privacy notice. We will collect the
						below-mentioned types of personal information when you
						download and create a profile on the Gasolina App:
					</p>
					<br />
					<ul>
						<li>
							<p>
								<strong>Title </strong>(optional)
							</p>
						</li>
						<li>
							<p>
								<strong>
									Full name i.e., Name and Surname;{" "}
								</strong>
								(Mandatory)
							</p>
						</li>
						<li>
							<p>
								<strong>Mobile number; </strong>(Mandatory)
							</p>
						</li>
						<li>
							<p>
								<strong>E-mail address; </strong>(Mandatory)
							</p>
						</li>
						<li>
							<p>
								<strong>Mobile device geolocation;</strong>{" "}
								(optional unless using the station locator
								functionality)
							</p>
						</li>
					</ul>
					<br />
					<h4>
						For what purposes and on what legal basis is personal
						information processed?
					</h4>
					<p>
						We will process, use and disclose the personal
						information referred to above for the following
						purposes:
					</p>
					<br />
					<ul>
						<li>
							<p>
								To adequately identify you and verify your
								identity when you create a profile on the
								Gasolina App and on each occasion that you log
								into the Gasolina App.
							</p>
						</li>
						<li>
							<p>
								To register you for Gasolina Rewards and process
								your personal information in accordance with our
								Gasolina Rewards Privacy Notice;
							</p>
						</li>
						<li>
							<p>For account fulfilment purposes.</p>
						</li>
						<li>
							<p>
								To tell you about exciting Gasolina or Gasolina
								partner product promotions and competitions
								which may interest you.
							</p>
						</li>
						<li>
							<p>
								Customer experience surveys and sentiment
								management;
							</p>
						</li>
						<li>
							<p>
								Customer service, compliments and complaints
								management.
							</p>
						</li>
						<li>
							<p>Entry of Gasolina competitions and promotions</p>
						</li>
					</ul>
					<br />
					<h4>
						Consent to Gasolina’s use of your personal data via the
						Gasolina App
					</h4>
					<p>
						The processing of your personal information is based on
						your consent. By registering a profile on the Gasolina
						App, you are consenting to the collection and processing
						of your personal information in accordance with this
						Privacy Notice.
					</p>
					<br />
					<p>
						You may withdraw your consent at any time. However,
						please note that this would require you to deactivate
						your profile / account and delete the Gasolina App from
						your mobile device.
					</p>
					<br />
					<h4>Direct Marketing preferences</h4>
					<p>
						If you are a Gasolina member, Gasolina may process your
						personal information for marketing purposes in
						accordance with the Gasolina privacy notice.
					</p>
					<br />
					<p>
						Furthermore, we may provide you with information on our
						products, services and competitions that we think you
						may be interested in. Certain advertisements or
						promotions may be tailored to you based on your
						preferences or your use of the Gasolina App or any of
						our products and services.
					</p>
					<br />
					<p>
						Should you not wish to receive any direct marketing
						communications from us, please follow the relevant
						prompts in the electronic communications (e.g., SMS,
						email) to unsubscribe.
					</p>
					<br />
					<h4>Who obtains your personal information?</h4>
					<p>
						Within Gasolina, your personal information will only be
						accessed by the individuals who need it in order to
						facilitate the purposes listed above.
					</p>
					<br />
					<p>
						We may have to share your personal information with
						specific third parties, including third-party service
						providers who are engaged to perform services on our
						behalf such as product or service delivery, project
						execution, technology, analytics and insights providers
						for the efficient management of the Gasolina App. We may
						also have to disclose your personal data with relevant
						regulatory bodies or law enforcement agencies as
						required by applicable law, and only insofar as required
						to fulfil our legal obligations.
					</p>
					<br />
					<p>
						Where reasonably possible, we first try to ensure that
						personal information is anonymised before disclosure.
						However, if this is not possible, we disclose only the
						minimum necessary personal information to fulfil the
						purposes listed above, in compliance with applicable
						data protection laws.
					</p>
					<br />
					<h4>
						Which privacy rights can you assert as a data subject?
					</h4>
					<p>
						POPIA provides you with certain rights in relation to
						the processing of your personal information, including
						the right to request details on your personal
						information stored and processed by us, objecting to the
						processing of your personal information under certain
						circumstances, and requesting that your personal
						information be rectified (where inaccurate or incorrect)
						or deleted. If you do object, we will carefully assess
						your objection and if we disagree with it, we will
						provide you with our reasons and continue to process the
						relevant personal information.
					</p>
					<br />
					<h4>Security Safeguards</h4>
					<p>
						We have implemented appropriate and reasonable,
						technical and organisational measures to prevent loss
						of, damage to, unauthorised use, processing or
						destruction of, and unlawful access to, your personal
						information.
					</p>
					<br />
					<h4>How long is your personal information stored?</h4>
					<p>
						We will securely and permanently delete your personal
						information in accordance with our relevant legal,
						regulatory and reporting as well as legitimate
						operational requirements. Therefore, we will only retain
						your personal information, for as long as it is
						necessary to fulfil the purposes for which we collected
						it or where we are legally entitled to do so.
					</p>
					<br />
					<p>
						If you have registered for Gasolina Rewards, your
						personal data will be retained / deleted in accordance
						with our Gasolina Rewards{" "}
						<NavLink to='/privacy-policy'>privacy notice</NavLink>.
					</p>
					<br />
					<p>Last review and update: 1 October 2024</p>
					<p>
						<strong>Seritoglo Proprietary Limited</strong>
					</p>
				</div>
			</CommonContent>
		</>
	);
};

export default PrivacyPolicyPage;
